import * as React from "react";
import { HeadFC } from "gatsby";
import styled from "styled-components";

export const Cont = styled.div`
  left: 50%;
  position: fixed;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`;

export const Heading = styled.h1``;

export const Link = styled.a``;

export const Page = styled.main`
  font-family: "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const Text = styled.p``;

const NotFoundPage = () => {
  return (
    <Page>
      <Cont>
        <Heading>Page not found!</Heading>
        <Text>
          <Link href="https://www.ormside.co.uk">Go to www.ormside.co.uk</Link>
        </Text>
      </Cont>
    </Page>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Ormside - Page not found!</title>;
